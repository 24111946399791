<template>
  <div>
    <div class="main">
      <v-row>
        <DialogConfirmation
          tag="edit"
          title="Alterar Dados"
          :show="exibirDialogAvisoEdicao"
          @confirm="alterarDadosColaborador()"
          @close="exibirDialogAvisoEdicao = false"
        >
          <template v-slot:body>
            <div class="mt-5">
              Esta ação alterará os seus dados. Deseja continuar?
            </div>
          </template>
        </DialogConfirmation>

        <v-col style="margin-top: 4.7rem" md="3" order-md="2">
          <v-avatar size="avatarSize" color="#f1f2f3" rounded>
            <v-img contain height="250" width="250" :src="avatar"></v-img>
          </v-avatar>

          <div v-if="status" style="width: 250px" class="text-center">
            <StatusChip class="mt-1" :color="getColor(status)">
              {{ status }}
            </StatusChip>
          </div>
        </v-col>

        <v-col offset-sm="12" md="9">
          <TitleH2 class="mt-3 mb-5 mx-3" title="Dados do Colaborador" />
          <p style="color: #c57c0f; font-weight: 500" class="ml-4 mb-1">
            Para alterar seus dados pessoais entre em contato com a CAAMA
          </p>
          <FormField
            :fields="fields"
            :validate="validacao"
            @change="onFormChange($event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end" offset-sm="12" md="9">
          <ButtonMain
            class="primary mr-5 mb-5"
            style="width: 12rem"
            animacao="spin"
            :text="alterandoDadosColaborador ? 'Alterando...' : 'Alterar'"
            :icone="alterandoDadosColaborador ? 'autorenew' : ''"
            :desabilitar="alterandoDadosColaborador"
            @click.native="exibirDialogAvisoEdicao = true"
          ></ButtonMain>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from '@/services/api'
import utilService from '@/services/UtilService'
import colaboradorService from '@/services/ColaboradorService'
import parceiroService from '@/services/ParceiroService'
import cepService from '@/services/CepService'
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from '@/components/atoms/StatusChip'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'

export default {
  components: {
    TitleH2,
    FormField,
    StatusChip,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      avatar: '',
      status: '',
      userCpf: '',
      rotaDeVolta: '/lista-colaboradores',
      alterandoDadosColaborador: false,
      exibirDialogAvisoEdicao: false,
      criandoFormulario: false,
      validacao: 0,
      dadosColaborador: {},
      dadosColaboradorCopia: {},
      listaUfs: [],
      enderecoCep: [],
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
        numeroConselho: 'Campo obrigatório quando o Conselho for selecionado'
      },
      fields: {
        nome: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 12,
          disabled: true,
          field: 'InputField',
          rules: []
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          disabled: true,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '###.###.###-##',
          rules: []
        },
        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          disabled: true,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##/##/####',
          rules: []
        },
        idUfNaturalidade: {
          label: 'UF Naturalidade',
          valueField: null,
          disabled: true,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: []
        },
        idCidadeNaturalidade: {
          label: 'Cidade Naturalidade',
          disabled: true,
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: []
        },
        profissao: {
          label: 'Profissão',
          disabled: true,
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: false,
          rules: []
        },
        idConselho: {
          label: 'Conselho Classe',
          disabled: true,
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: []
        },
        numeroConselho: {
          label: 'Número do Conselho',
          disabled: true,
          readonly: true,
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          disabled: true,
          key: 2,
          rules: []
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          field: 'InputField',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
              this.validadores.invalido
          ]
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          cols: 2,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 9 ||
              this.validadores.invalido
          ]
        },

        enderecoDivisor: {
          cols: 12,
          label: 'Endereço',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '##.###-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: null,
          cols: 6,
          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idUf: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        idCidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        }
      }
    }
  },

  created() {
    this.receberUFs()
    this.receberConselhosClasse()
    this.checkCpfValido()
    this.receberProfissoes()
  },

  methods: {
    checkCpfValido() {
      if (this.$store.getters['auth/cpf']) {
        this.userCpf = this.$store.getters['auth/cpf']
        this.receberDadosColaborador()
      } else {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Por favor, refaça seu login!',
          title: 'CPF não informado!'
        })
      }
    },
    receberDadosColaborador() {
      if (this.userCpf.length === 11) {
        colaboradorService
          .detalharColaboradorPorCpf(this.userCpf)
          .then(({ data }) => {
            this.status = data.descricaoStatus
            this.avatar = `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${data.nome}`
            this.dadosColaborador = data
            this.dadosColaboradorCopia = JSON.parse(JSON.stringify(data))
            this.criarFormulario(data)
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
      } else {
        this.$root.vtoast.show({
          status: 'error',
          text: 'Por favor, refaça seu login!',
          title: 'CPF inválido!'
        })
      }
    },

    receberConselhosClasse() {
      utilService.conselhosClasse().then(({ data }) => {
        this.fields.idConselho.items = data
      })
    },

    criarFormulario(data) {
      this.criandoFormulario = true
      Object.entries(data).map(([key, value]) => {
        if (this.fields[key]) {
          this.fields[key].valueField = value
          //this.fields[key]['readonly'] = true
          //this.fields[key]['disabled'] = true
          //this.fields[key]['rules'] = []

          if (key === 'idConselho' && value) {
            this.fields.numeroConselho.rules = [
              (value) =>
                (this.fields.idConselho.value && !!value) ||
                'Campo obrigatório quando o Conselho for selecionado'
            ]
          }
        }
      })
    },

    receberProfissoes() {
      utilService.profissoes().then(({ data }) => {
        this.fields.profissao.items = data
      })
    },

    receberUFs() {
      api.getUf().then(({ data }) => {
        this.listaUfs = data
        this.fields.idUfNaturalidade.items = data
        this.fields.idUf.items = data
      })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    validarCamposFormulario(campos) {
      let campoInvalido = false
      for (const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value
        const regras = value.rules
        let erroCampo = false

        if (regras && regras.length) {
          for (const regra of regras) {
            if (
              regra(valorCampo) === this.validadores.obrigatorio ||
              regra(valorCampo) === this.validadores.invalido ||
              regra(valorCampo) === this.validadores.numeroConselho
            ) {
              erroCampo = true
              break
            }
          }
        }

        if (erroCampo) {
          campoInvalido = true
          break
        }
      }

      return campoInvalido
    },

    alterarDadosColaborador() {
      this.exibirDialogAvisoEdicao = false

      if (this.validarCamposFormulario(this.fields)) {
        ++this.validacao
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos'
        })

        return
      }

      this.alterandoDadosColaborador = true
      const body = {
        nome: this.dadosColaboradorCopia?.nome,
        cpf: this.dadosColaboradorCopia?.cpf,
        cidadeNaturalidade: this.dadosColaboradorCopia?.idCidadeNaturalidade,
        dataNascimento: this.dadosColaboradorCopia?.dataNascimento,
        conselhoClasse: this.dadosColaboradorCopia?.idConselho,
        numeroConselho: this.dadosColaboradorCopia?.numeroConselho,
        descricaoProfissao: this.dadosColaboradorCopia?.descricaoProfissao,
        profissao: this.dadosColaboradorCopia?.profissao,

        email: this.fields.email.value,
        ddd: this.fields.ddd.value,
        telefone: this.fields.telefone.value,

        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        logradouro: this.fields.logradouro.value,
        complemento: this.fields.complemento.value,
        cidadeEndereco: this.fields.idCidadeEndereco.value,
        numero: this.fields.numero.value
      }

      colaboradorService
        .atualizarColaborador(body, this.dadosColaborador.id)
        .then(() => {
          this.receberDadosColaborador()
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Colaborador atualizado com sucesso.'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => (this.alterandoDadosColaborador = false))
    },

    onFormChange(campo) {
      if (campo.variableName === 'idConselho') {
        this.fields.numeroConselho.valueField = ''
      }
    },

    formatarData(data) {
      return `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`
    },

    getColor(status) {
      status = status.toUpperCase()
      if (status === 'ATIVO') return 'green'
      if (status === 'INATIVO') return 'red'
      if (status === 'EM ANALISE') return 'orange'
      return ''
    },

    verificarCep(cep) {
      cepService.buscarCep(cep).then(({ data }) => {
        if (data.erro) {
          this.fields.bairro.valueField = null
          this.fields.logradouro.valueField = null
          this.fields.uf.valueField = null
          this.fields.uf.value = null
          this.fields.idCidadeEndereco.valueField = null
          this.fields.idCidadeEndereco.value = null
          return
        }

        this.fields.bairro.valueField = data.bairro
        this.fields.logradouro.valueField = data.logradouro
        this.enderecoCep = data
      })
    }
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (this.criandoFormulario) {
        this.criandoFormulario = false
        return
      }

      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    'fields.idUfNaturalidade.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidadeNaturalidade')
    },

    'fields.idConselho.value': function (value) {
      ++this.fields.numeroConselho.key

      if (value) {
        this.fields.numeroConselho.disabled = false
        this.fields.numeroConselho.rules = [
          (value) =>
            (this.fields.idConselho.value && !!value) ||
            'Campo obrigatório quando o Conselho for selecionado'
        ]
      } else {
        this.fields.numeroConselho.value = null

        this.fields.numeroConselho.rules = []
        this.fields.numeroConselho.disabled = true
      }
    },

    'fields.idUf.value': function (id) {
      this.receberCidadesPorUF(id, 'idCidadeEndereco')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.idUf.valueField = uf.id
          this.fields.idUf.value = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.fields.idCidadeEndereco.items = data

            this.fields.idCidadeEndereco.items.filter((cidade) => {
              if (
                cidade.nome ===
                valor.localidade
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
              ) {
                this.fields.idCidadeEndereco.value = cidade.id
                this.fields.idCidadeEndereco.valueField = cidade.id
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.footer-pagina {
  margin-top: 2rem;
}
</style>
