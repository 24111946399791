<template>
  <div>
    <v-tabs v-model="tab">
      <v-tabs-slider color="grey lighten-3"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.content">
          <v-container>
            <component class="mt-5" :is="item.component" />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import Perfil from './Perfil.vue'
import EspecializacoesPerfil from './EspecializacoesPerfil.vue'

export default {
  components: {
    Perfil,
    EspecializacoesPerfil
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: 'Dados',
          content: 'tab-dados',
          component: 'Perfil'
        },
        {
          tab: 'Especializações',
          content: 'tab-especializacoes',
          component: 'EspecializacoesPerfil'
        }
      ]
    }
  }
}
</script>

<style scoped>
.ajuste {
  width: 80%;
}
</style>
