<template>
  <v-container>
    <DialogConfirmation
      :width="700"
      :show="dialogResetar"
      @close="dialogResetar = false"
      @confirm="resetarSenha(itemResetar)"
      title="Redefinir Senha"
    >
      <template v-slot:body>
        <p>
          Esta ação irá redefinir a senha do usuario
          {{ itemResetar.nome }} para a senha padrão, deseja continuar?
        </p>
      </template></DialogConfirmation
    >
    <DialogConfirmation
      :width="700"
      :show="dialog"
      :key="keyDialog"
      :tag="tagDialog"
      :title="dialogTitle"
      @close="closeDialog()"
      @confirm="confirmDialog()"
    >
      <template v-if="tagDialog === 'add' || tagDialog === 'edit'" v-slot:body>
        <v-form ref="teste" class="form-fields fields">
          <InputField
            label="Nome"
            :valueField.sync="users.nome"
            :rules="fields.nome.rules"
            @update:value="users.nome = $event"
            :cols="6"
            placeholder="Nome"
          />
          <InputField
            label="Email"
            :valueField.sync="users.email"
            :rules="fields.nome.rules"
            @update:value="users.email = $event"
            :cols="6"
            placeholder="Email"
          />
          <SelectField
            label="Perfil"
            :valueField.sync="users.perfil"
            :rules="fields.nome.rules"
            @update:value="users.perfil = $event"
            keyName="nome"
            keyValue="id"
            :items="itemsPerfis"
            :cols="4"
            placeholder="Nome"
          />
          <InputMaskField
            label="CPF/CNPJ"
            :valueField.sync="users.cpf"
            :rules="fields.nome.rules"
            @update:value="users.cpf = $event"
            maskFormat="###.###.###-##"
            :cols="4"
            placeholder="Nome"
          />
          <InputMaskField
            label="Telefone"
            :valueField.sync="users.telefone"
            :rules="fields.nome.rules"
            @update:value="users.telefone = $event"
            maskFormat="(##)#####-####"
            :cols="4"
            placeholder="(DDD)98765-4321"
          />
          <InputField
            label="Usuario"
            :cols="12"
            :valueField.sync="users.username"
            :rules="fields.username.rules"
            @update:value="users.username = $event"
            placeholder="Nome"
          />
          <InputValidacaoSenha
            v-if="tagDialog === 'add'"
            label="Senha"
            type="password"
            :cols="12"
            :valueField="users.senha"
            :rules="fields.senha.rules"
            :validacaoPopup="fields.senha.validacaoPopup"
            :validadores="fields.senha.validadores"
            @update:value="users.senha = $event"
          />
          <InputField
            v-if="tagDialog === 'add'"
            label="Confirmar Senha"
            type="password"
            placeholder="Nome"
            :cols="12"
            :iconeSenha="fields.confirmarSenha.iconeSenha"
            :valueField="users.senhaConfirmar"
            :rules="fields.confirmarSenha.rules"
            @update:value="users.senhaConfirmar = $event"
          />
        </v-form>
      </template>
      <template v-else v-slot:body>
        <div>TEM CERTEZA QUE DESEJA DESATIVAR?</div>
      </template>
    </DialogConfirmation>

    <DialogConfirmation
      :width="700"
      :show="dialogFiltro"
      @close="dialogFiltro = false"
      @confirm="
        filtroAplicado = true
        aplicarFiltro({ resetPagina: true })
      "
      tag="add"
      title="Filtros"
    >
      <template v-slot:body>
        <v-row>
          <SelectField
            :items="perfis"
            label="Perfil"
            keyValue="id"
            :cols="6"
            keyName="nome"
            :valueField="filtro.perfil"
            @update:value="filtro.perfil = $event"
          />
          <InputField
            label="Nome"
            @update:value="filtro.nome = $event"
            :cols="6"
            :valueField="filtro.nome"
          />
          <InputField
            label="Usuario"
            @update:value="filtro.usuario = $event"
            :valueField="filtro.usuario"
            :cols="6"
          />
          <InputMaskField
            label="CPF/CNPJ"
            maskFormat="###.###.###-##"
            @update:value="filtro.cpf = $event"
            :valueField="filtro.cpf"
            :cols="6"
          />
        </v-row>
      </template>
    </DialogConfirmation>

    <CrudTable
      :keyPaginacao="keyPaginacao"
      :headers="usuario.headers"
      :items="usuarios"
      :pagination="pagination"
      :loading="loadingTable"
      @actualPage="onChangePaginacao"
    >
      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <StatusChip
          :success="item.ativo ? true : false"
          :secondary="item.ativo ? false : true"
        >
          <span> {{ item.ativo ? 'ATIVO' : 'DESATIVADO' }} </span>
        </StatusChip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain text="Editar Usuário" top>
            <ButtonIcon
              class="mx-1"
              :warning="true"
              @click.native="editUser(item)"
              icon="pencil"
            />
          </TooltipMain>
          <TooltipMain top v-if="item.ativo" text="Desativar Usuário">
            <ButtonIcon
              class="mx-1"
              :secondary="true"
              @click.native="deleteUser(item)"
              icon="close-circle"
            />
          </TooltipMain>
          <TooltipMain top v-else text="Ativar Usuário">
            <ButtonIcon
              class="mx-1"
              :success="true"
              @click.native="enableUser(item)"
              icon="check-circle"
            />
          </TooltipMain>
          <TooltipMain top text="Redefinir Senha">
            <ButtonIcon
              class="mx-1"
              :primary="true"
              @click.native="
                dialogResetar = true
                itemResetar = item
              "
              icon="key"
            />
          </TooltipMain>
        </v-row>
      </template>

      <template v-slot:top>
        <v-toolbar :class="$vuetify.breakpoint.xs ? 'scroll' : ''" flat>
          <ButtonMain
            :primary="true"
            text="Adicionar Usuário"
            @click.native="openModalUsuario('add')"
          />
          <v-spacer></v-spacer>

          <ButtonMain
            class="mx-1"
            :primary="true"
            @click.native="dialogFiltro = true"
            text="Filtrar"
          />
          <ButtonMain
            v-if="filtroAplicado"
            class="mx-1"
            @click.native="getAllUsuarios()"
            :warning="true"
            text="Limpar Filtros"
          />
        </v-toolbar>
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import api from '@/services/api'
import TitleH2 from '../components/atoms/title/TitleH2'
import CrudTable from '../components/molecules/tables/CrudTable'
import FormField from '../components/atoms/forms/FormField'
import ButtonMain from '../components/atoms/buttons/ButtonMain.vue'
import TooltipMain from '../components/atoms/TooltipMain.vue'
import ButtonIcon from '../components/atoms/buttons/ButtonIcon.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import StatusChip from '@/components/atoms/StatusChip.vue'
import InputField from '../components/atoms/fields/InputField.vue'
import SelectField from '../components/atoms/fields/SelectField.vue'
import InputMaskField from '../components/atoms/fields/InputMaskField.vue'
import InputValidacaoSenha from '../components/atoms/fields/InputValidacaoSenha.vue'
import { validadoresSenha } from '../mixins/data/validadoresSenha.js'

export default {
  components: {
    TitleH2,
    CrudTable,
    FormField,
    ButtonMain,
    TooltipMain,
    ButtonIcon,
    DialogConfirmation,
    StatusChip,
    InputField,
    SelectField,
    InputMaskField,
    InputValidacaoSenha
  },

  mixins: [validadoresSenha],

  data() {
    return {
      keyPaginacao: 0,
      dialogResetar: false,
      itemResetar: {},
      redefinirSenha: false,
      values: {},
      itemsPerfis: [],
      loadingTable: false,
      dialog: false,
      tagDialog: '',
      dialogFiltro: false,
      tagDialogFiltro: '',
      user: null,
      users: {
        nome: '',
        email: '',
        perfil: '',
        username: '',
        senha: '',
        senhaConfirmar: '',
        cpf: '',
        telefone: ''
      },
      dialogDelete: false,
      editedIndex: false,
      fields: {},
      usuario: {
        headers: [
          { text: 'Nome', align: 'start', value: 'nome' },
          { text: 'Usuário', align: 'start', value: 'username' },
          { text: 'CPF', align: 'start', value: 'cpf' },
          { text: 'Ações', value: 'acoes', sortable: false },
          { text: 'Status', align: 'center', width: 100, value: 'status' }
        ]
      },
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      },
      usuarios: [],
      perfis: [],
      filtroAplicado: false,
      filtro: {
        nome: '',
        cpf: '',
        usuario: '',
        perfil: ''
      },
      keyDialog: 0
    }
  },

  mounted() {
    this.fields = {
      nome: {
        field: 'InputField',
        label: 'Nome',
        value: null,
        placeholder: 'Nome',
        cols: 6,
        rules: [(value) => !!value || 'Campo obrigatório']
      },
      email: {
        field: 'InputField',
        label: 'Email',
        value: null,
        placeholder: 'Email',
        cols: 6,
        rules: [(value) => !!value || 'Campo obrigatório']
      },
      perfil: {
        field: 'SelectField',
        label: 'Perfil',
        value: null,
        keyName: 'nome',
        keyValue: 'id',
        placeholder: 'Perfil',
        cols: 4,
        rules: [(value) => !!value || 'Campo obrigatório'],
        items: []
      },
      cpf: {
        field: 'InputMaskField',
        label: 'CPF',
        value: null,
        placeholder: 'CPF',
        maskFormat: `###.###.###-##`,
        cols: 4,
        rules: [(value) => !!value || 'Campo obrigatório']
      },
      telefone: {
        field: 'InputMaskField',
        label: 'Telefone',
        value: null,
        placeholder: 'Telefone',
        maskFormat: '(##) #####-####',
        cols: 4,
        rules: [(value) => !!value || 'Campo obrigatório']
      },
      username: {
        field: 'InputField',
        label: 'Usuário',
        value: null,
        placeholder: 'Usuário',
        cols: 4,
        rules: [
          (value) => !!value || 'Campo obrigatório',
          (v) => v?.length >= 8 || 'Minimo 8 caracteres'
        ]
      },

      senha: {
        field: 'InputValidacaoSenha',
        label: 'Senha',
        valueField: null,
        exibirValidacao: false,
        validacaoPopup: false,
        type: 'password',
        placeholder: 'Senha',
        cols: 4,
        rules: this.REGRAS_SENHA,
        validadores: this.CONDICOES_SENHA
      },

      confirmarSenha: {
        field: 'InputField',
        label: 'Confirmar senha',
        value: null,
        type: 'password',
        placeholder: 'Confirmar senha',
        iconeSenha: 'mdi-eye-outline',
        cols: 4,
        rules: [
          (value) => !!value || 'Campo obrigatório',
          (value) => value === this.users.senha || 'As senhas não são iguais'
        ]
      }
    }
  },

  methods: {
    resetarSenha(item) {
      this.dialogResetar = false
      api.putResetarSenhaUsuario(item.id).then((r) => {
        this.$root.vtoast.show({
          time: 20000,
          status: 'success',
          text: r.data,
          title: 'Senha Resetada com sucesso!'
        })
      })
    },

    aplicarFiltro(opcoes = { resetPagina }) {
      if (opcoes.resetPagina) {
        this.resetarPaginacao()
      }

      this.loadingTable = true
      const filtro = {
        ...this.filtro,
        page: opcoes.resetPagina ? 0 : this.pagination.page,
        size: this.pagination.perPage,
        sort: 'nome'
      }

      api
        .getUsuarios(filtro)
        .then(({ data }) => {
          this.usuarios = data.content
          this.pagination.total = data.totalPages
          this.dialogFiltro = false
          this.loadingTable = false
        })
        .catch((_) => {
          this.loadingTable = false
        })
    },

    getAllUsuarios() {
      this.resetarPaginacao()
      this.loadingTable = true
      this.filtro = {}
      this.filtroAplicado = false

      const filtro = {
        page: 0,
        size: this.pagination.perPage,
        sort: 'nome'
      }

      api.getUsuarios(filtro).then(({ data }) => {
        this.usuarios = data.content
        this.pagination.total = data.totalPages
      })

      this.loadingTable = false
    },

    onChangePaginacao(page) {
      this.loadingTable = true
      this.pagination.page = page - 1
      this.aplicarFiltro({ resetPagina: false })
    },

    resetarPaginacao() {
      ++this.keyPaginacao
    },

    submit() {
      api
        .postUsuario(this.values)
        .then((res) => {
          this.dialog = true
          this.values = {}
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Usuário cadastrado.'
          })
          this.getAllUsuarios()
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    editUser(usuario) {
      this.user = usuario
      api
        .getUsuarioById(this.user.id)
        .then(({ data }) => {
          Object.getOwnPropertyNames(this.users).map((item) => {
            if (item) {
              Object.entries(data).forEach(([key, value]) => {
                if (value && item === key) {
                  if (item === 'cpf' || item === 'telefone') {
                    this.users[item] = value.replace(/[^0-9]/g, '')
                  } else {
                    this.users[item] = value
                  }
                }
              })
            }
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response
              ? err.response?.data.mensagemUsuario
              : String(err),
            title: err.response ? err.response?.data.titulo : ''
          })
        })
      this.openModalUsuario('edit')
    },

    deleteUser(usuario) {
      this.user = usuario
      this.openModalUsuario('delete')
    },

    enableUser(user) {
      api
        .putAtivarUsuario(user.id)
        .then(({ data }) => {
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Usuário Ativado.'
          })
          this.getAllUsuarios()
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 45000
          })
        })
    },

    confirmDialog() {
      if (this.tagDialog === 'add') {
        const valid = Object.entries(this.users).every(
          ([key, value]) => value.length || typeof value === 'number'
        )
        if (valid) {
          api
            .postUsuario(this.users)
            .then(({ data }) => {
              this.$root.vtoast.show({
                status: 'success',
                title: 'Sucesso!',
                text: 'Usuario cadastrado'
              })

              this.dialog = false
              this.getAllUsuarios()
            })
            .catch((err) => {
              this.$root.vtoast.show({
                status: 'error',
                text: err.response?.data.mensagemUsuario,
                title: err.response?.data.titulo
              })
            })
        } else {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Formulário inválido!',
            text: 'Preencha todos os campos.'
          })
        }
      } else if (this.tagDialog === 'edit') {
        const usuario = { ...this.users, senha: null, senhaConfirmar: null }

        api
          .putUsuario(this.user.id, usuario)
          .then(({ data }) => {
            this.getAllUsuarios()
            this.$root.vtoast.show({
              status: 'success',
              text: 'Usuário atulizado!',
              title: 'Sucesso'
            })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
        this.dialog = false
      } else if (this.tagDialog === 'delete') {
        api.deleteUserId(this.user.id).then((_) => {
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Usuário Desativado.'
          })
          this.getAllUsuarios()
        })
        this.dialog = false
      }
    },

    closeDialog() {
      this.keyDialog++
      this.dialog = false
    },

    openModalUsuario(tag) {
      this.dialog = true
      this.tagDialog = tag
      this.keyDialog++
      this.users = {
        nome: '',
        email: '',
        perfil: '',
        username: '',
        senha: '',
        senhaConfirmar: '',
        cpf: '',
        telefone: ''
      }
    }
  },

  computed: {
    formTitle() {
      return this.editedIndex === false ? 'Adicionar Usuário' : 'Editar Usuário'
    },
    dialogTitle() {
      if (this.tagDialog === 'add') {
        return `Adicionar Usuário`
      }
      if (this.tagDialog === 'edit') {
        return `Editar Usuário - ${this.user ? this.user.nome : ''}`
      }
      if (this.tagDialog === 'delete') {
        return `Desativar Usuário - ${this.user ? this.user.nome : ''}`
      }
    }
  },

  created() {
    api.getPerfis().then(({ data }) => {
      this.fields.perfil.items = data
      this.itemsPerfis = data
    })
    this.getAllUsuarios()

    api.getPerfis().then(({ data }) => {
      this.perfis = data
    })
  }
}
</script>

<style scoped>
.scroll {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.fields {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: flex-start;
  flex: 1;
}
.form-fields {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
</style>
