<template>
  <div>
    <DialogConfirmation
      :persistente="true"
      :width="tagDialog === 'add' ? 850 : 500"
      :show="exibirDialogConfirmacao"
      :tag="tagDialog"
      :title="`${
        tagDialog === 'delete' ? 'Desvincular' : 'Vincular'
      } Especialização`"
      @close="fecharDialog()"
      @confirm="confirmarDialog()"
    >
      <template v-slot:body>
        <div v-if="tagDialog === 'add'">
          <div class="mt-4">
            <v-alert dense type="warning">
              {{ avisoVinculo }}
            </v-alert>
          </div>

          <v-autocomplete
            class="mt-5"
            v-model="especializacaoSelecionada"
            label="Selecione uma especialização"
            item-text="descricao"
            item-value="id"
            outlined
            return-object
            no-data-text="Especialiazação não encontrada"
            :items="especializacoesNaoVinculadas"
          >
          </v-autocomplete>
        </div>

        <p class="mt-5" v-if="tagDialog === 'activate'">
          Deseja vincular a especialização
          {{ especializacaoSelecionada.descricao }} à este colaborador?
        </p>

        <p class="mt-5" v-if="tagDialog === 'delete'">
          Deseja desvincular a especialização
          {{ especializacaoSelecionada.descricao }} deste colaborador?
        </p>
      </template>
    </DialogConfirmation>

    <div class="px-4">
      <v-alert dense type="warning" class="pr-4">
        {{ avisoVinculo }}
      </v-alert>
    </div>

    <CrudTable
      tabelaKey="id"
      :headers="headers"
      :items="especializacoesVinculadas"
      :pagination="pagination"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <ButtonMain
            :primary="true"
            text="Vincular Especialização"
            @click.native="abrirDialogVincularEspecializaco()"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          color="green"
          class="mr-2"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'activate'
            especializacaoSelecionada = item
          "
          :loading="!item.ativo && item.atualizandoStatus"
          :disabled="item.ativo || item.atualizandoStatus"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-check-circle
              </v-icon>
            </template>
            <span>Vincular Especialização</span>
          </v-tooltip>
        </v-btn>
        <v-btn
          small
          color="red"
          class="white--text"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'delete'
            especializacaoSelecionada = item
          "
          :loading="item.ativo && item.atualizandoStatus"
          :disabled="!item.ativo || item.atualizandoStatus"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-close-circle
              </v-icon>
            </template>
            <span>Desvincular Especialização</span>
          </v-tooltip>
        </v-btn>
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        <span :class="item.ativo ? '' : 'especializacao-desviculada'">{{
          item.descricao
        }}</span>
      </template>
    </CrudTable>
  </div>
</template>

<script>
import colaboradorService from '@/services/ColaboradorService'
import CrudTable from '../../components/molecules/tables/CrudTable'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation'

export default {
  components: {
    CrudTable,
    ButtonMain,
    DialogConfirmation
  },

  data() {
    return {
      avisoVinculo:
        'O vínculo da especialização é editado apenas por usuários com permissão. Caso queira editar, entre em contato com a CAAMA.',
      tagDialog: '',
      dadosColaborador: null,
      especializacaoSelecionada: {},
      especializacoesNaoVinculadas: [],
      especializacoesVinculadas: [],
      loading: false,
      cadastrandoColaborador: false,
      exibirDialogConfirmacao: false,

      headers: [
        { text: 'Descrição', value: 'descricao', sortable: false },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 160,
          sortable: false
        }
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  created() {
    this.checkCpfValido()
  },

  methods: {
    abrirDialogVincularEspecializaco() {
      this.exibirDialogConfirmacao = true
      this.tagDialog = 'add'

      if (!this.getColaborador()?.profissao) {
        this.$root.vtoast.show({
          status: 'error',
          title: 'O Colaborador está sem Profissão vinculada'
        })
        return
      }

      this.receberEspecializacoesNaoVinculadas()
    },

    checkCpfValido() {
      if (this.$store.getters['auth/cpf']) {
        this.userCpf = this.$store.getters['auth/cpf']
        this.receberDadosColaborador()
      }
      return
    },

    receberDadosColaborador() {
      if (this.userCpf.length !== 11) {
        this.$root.vtoast.show({ status: 'error', title: 'CPF inválido!' })
        return
      }

      colaboradorService
        .detalharColaboradorPorCpf(this.userCpf)
        .then(({ data }) => {
          this.dadosColaborador = data
        })
        .then(() => {
          this.receberEspecializacoesColaborador()
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
    },

    receberEspecializacoesColaborador() {
      colaboradorService
        .especializacoesPorColaborador(this.getColaborador()?.id)
        .then(({ data }) => {
          this.especializacoesVinculadas = data.map((item) => {
            return {
              ...item,
              status: item.ativo ? 'VINCULADA' : 'DESVINCULADA',
              atualizandoStatus: false
            }
          })
        })
    },

    fecharDialog() {
      this.exibirDialogConfirmacao = false
      this.especializacaoSelecionada = ''
      this.receberEspecializacoesColaborador()
    },

    abrirDialogVincularEspecializaco() {
      this.exibirDialogConfirmacao = true
      this.tagDialog = 'add'
      this.receberEspecializacoesNaoVinculadas()
    },

    receberEspecializacoesNaoVinculadas() {
      const params = {
        colaborador: this.getColaborador()?.id,
        profissao: this.getColaborador()?.profissao
      }

      colaboradorService
        .especializacoesNaoVinculadasAoColaboradorPorProfissao(params)
        .then(({ data }) => {
          this.especializacoesNaoVinculadas = data
        })
    },

    confirmarDialog() {
      this.exibirDialogConfirmacao = false
      const especializacao = this.especializacoesVinculadas.find(
        (esp) => esp.id === this.especializacaoSelecionada.id
      )

      if (this.tagDialog === 'add') {
        this.vincularEspecializacao()
      } else if (this.tagDialog === 'activate') {
        this.revincularEspecializacao(especializacao)
      } else if (this.tagDialog === 'delete') {
        this.desvincularEspecializacao(especializacao)
      }
    },

    vincularEspecializacao() {
      if (Object.keys(this.especializacaoSelecionada).length) {
        const body = {
          idColab: Number(this.getColaborador()?.id),
          idEsp: Number(this.especializacaoSelecionada.id)
        }

        colaboradorService
          .vincularEspecializacaoAoColaborador(body)
          .then(() => {
            this.$root.vtoast.show({
              status: 'success',
              text: 'Especialização vinculada com sucesso.',
              title: 'SUCESSO!'
            })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => {
            this.especializacaoSelecionada = {}
            this.receberEspecializacoesColaborador()
          })
      } else {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Nenhum item selacionado'
        })
      }
    },

    desvincularEspecializacao(especializacao) {
      especializacao.atualizandoStatus = true

      colaboradorService
        .desativarEspecializacaoAoColaborador(
          this.getColaborador()?.id,
          this.especializacaoSelecionada.idEspecializacao
        )
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Especialização desvinculada com sucesso.',
            title: 'SUCESSO!'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
        .then(() => {
          this.especializacaoSelecionada = {}
          especializacao.atualizandoStatus = false
          this.receberEspecializacoesColaborador()
        })
    },

    revincularEspecializacao(especializacao) {
      especializacao.atualizandoStatus = true

      colaboradorService
        .ativarEspecializacaoAoColaborador(
          this.getColaborador()?.id,
          this.especializacaoSelecionada.idEspecializacao
        )
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Especialização vinculada com sucesso.',
            title: 'SUCESSO!'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
        .then(() => {
          this.especializacaoSelecionada = {}
          especializacao.atualizandoStatus = false
          this.receberEspecializacoesColaborador()
        })
    },

    concluirCadastro() {
      if (
        !this.especializacoesVinculadas.length ||
        !this.especializacoesVinculadas.find((esp) => esp.ativo)
      ) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Atenção!',
          text: 'Pelo menos um vínculo com especialização deve estar ativo.',
          time: 30000
        })

        return
      }

      sessionStorage.setItem('estado_cadastro_colaborador', 'concluido')
      this.$emit('concluirCadastro')
    },

    voltarPasso() {
      this.$emit('voltarPasso')
    },

    getColaborador() {
      return this.dadosColaborador
    }
  }
}
</script>

<style>
.especializacao-desviculada {
  color: #9e9e9e;
  text-decoration: line-through;
}

.footer-cadastro {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem 1rem;
}

.footer-pagina {
  margin-top: 2rem;
}
</style>
